import(/* webpackChunkName: "nav" */ "shared/vanilla/nav.js");
import { plugin, defaultConfig } from "@formkit/vue";
import { storageFactory } from 'shared/tools/localStorageFactory';
/* Design system icons */
import { iconCheckmark } from "@coloplast/design-system/dist/exports/icons";
/* Design system styles - These could be imported via global script, component script, or at a global level */
import "@coloplast/design-system/dist/components/formkit/formkit.css";

import 'shared/vanilla/console';


import { createApp, defineAsyncComponent } from 'vue';

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import initNav from "shared/vanilla/nav";
import { createEventBus } from 'shared/tools/eventBus';
// replacement for the $eventBus
const eventBus = createEventBus();

initNav();

import GlobalSettings from 'multisite/components/globalSettings.vue';
import MiniBasketCount from 'shared/components/product/miniBasketCount.vue';
import SampleButton from 'shared/components/product/sampleButton.vue';
import ExternalLinksPopup from 'shared/components/externalLinksPopup.vue';
import ModalDialog from 'shared/components/modalDialog.vue';
import BasketNavTracker from 'shared/components/trackers/basketNavTracker.vue';
import FormPostTracker from 'shared/components/trackers/formPostTracker.vue';
import Spinner from 'shared/components/spinner.vue';
import GlobalSpinner from 'shared/components/globalSpinner.vue';
import PhoneField from 'shared/components/phoneField.vue';
import Breadcrumb from 'shared/components/BreadcrumbElements/Breadcrumb.vue';
import LoginExpiredModal from 'shared/components/loginExpiredModal/loginExpiredModal.vue';

import LoginTracker from 'shared/components/trackers/loginTracker.vue';
import QuickSampleTracker from 'shared/components/trackers/quickSampleTracker.vue';

// // Starship - Basket
import FlyOutBasketActivator from 'shared/components/basket/flyOutBasketActivator.vue';

import store from 'shared/store';
import { initPostbackScrollPosition } from 'shared/tools/postbackscrollpos.js';
import boot from 'shared/vanilla/boot.js';
import ieNotification from 'shared/tools/ieNotification.js';
import initHeylo from 'shared/vanilla/initHeylo.js';
import dateTime from "shared/components/dateTime.vue";

// above the fold imports
import ImageGallerySkeleton from 'shared/components/product/skeletons/imageGallerySkeleton.vue';

import { initFormKitSchema } from 'shared/utils/initFormKitSchema';
import { initGlobalAxios } from "shared/tools/initGlobalAxios";

import './main.scss';

export function initSkeleton() {
	const imageGallerySkeleton = document.querySelectorAll('[data-image-gallery-skeleton]');
	if (imageGallerySkeleton) {
		imageGallerySkeleton.forEach(e => {
			const app = createApp({
				name: 'image gallery skeleton',
			});
			app.use(store);
			app.component('image-gallery-skeleton', ImageGallerySkeleton);
			app.mount(e);
		});
	}
}
initSkeleton();

window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload()
    }
};

function initGlobalSettingsApp() {
	const globalSettingsContainer = document.getElementById('global-settings-container');
	if (globalSettingsContainer) {
		const app = createApp({
			name: 'GlobalSettingsApp',
		});
		app.use(store);
		app.component('global-settings', GlobalSettings);
		app.mount(globalSettingsContainer);
	}
}

export function initVueApps() {
	const menuSampleContainer = document.getElementById('menu-sample-btn');
	if (menuSampleContainer) {
		const app = createApp({
			name: 'menu sampler',
		});
		app.use(store);
		app.component('sample-button', SampleButton);
		app.mount(menuSampleContainer);
	}

	const productWrapper = document.getElementById('product-wrapper');
	if (productWrapper) {
		const app = createApp({
			name: 'product list'
		});
		app.use(store);
		app.component('product-wrapper', defineAsyncComponent(
			() => import(/* webpackChunkName: "product-list" */ 'shared/components/product/productList.vue')
		));
		const productFinderMount = document.querySelector('#product-finder[data-standalone]');
		if (productFinderMount) {
			app.component('product-finder', defineAsyncComponent(
				() => import(/* webpackChunkName: "product-finder" */ 'shared/components/product/productFinder.vue')
			));
		}
		app.mount(productWrapper);
	}

	const hcpProductWrapper = document.getElementById('hcp-product-wrapper');
	if (hcpProductWrapper) {
		const app = createApp({
			name: 'hcp product list'
		});
		app.use(store);
		app.component('hcp-product-wrapper', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-product-list" */ 'shared/components/product/hcpProduct/HcpProductList.vue')
		));
		app.mount(hcpProductWrapper);
	}

	const miniBasketMount = document.getElementById('mini-basket');
	if (miniBasketMount) {
		const app = createApp({
			name: 'mini basket',
		});
		app.use(store);
		app.config.globalProperties.$eventBus = eventBus;
		app.component('mini-basket', defineAsyncComponent(
			() => import(/* webpackChunkName: "mini-basket" */ 'shared/components/product/miniBasket.vue')
		));
		app.mount(miniBasketMount);
	}

	const dateTimes = document.querySelectorAll('.date-time');
	if (dateTimes) {
		dateTimes.forEach(dateTimeEl => {
			const app = createApp({
				name: 'dateTime',
			});
			app.use(store);
			app.component('date-time', dateTime);
			app.mount(dateTimeEl);
		});
	}

	const imageGalleryMount = document.querySelectorAll('[data-image-gallery]');
	if (imageGalleryMount) {
		imageGalleryMount.forEach(e => {
			const app = createApp({
				name: 'image gallery',
			});
			app.use(store);
			app.component('image-gallery', defineAsyncComponent(
				() => import(/* webpackChunkName: "multisite-image-gallery" */ 'shared/components/product/imageGallery.vue')
			));
			app.mount(e);
		});
	}

	const addToBasketMount = document.getElementById('add-to-basket');
	if (addToBasketMount) {
		const app = createApp({
			name: 'AddToBasketApp',
		});
		app.use(store);
		app.config.globalProperties.$eventBus = eventBus;
		initFormKitSchema(app);
		app.component('variant-selector', defineAsyncComponent(
			() => import(/* webpackChunkName: "variant-selector" */ 'shared/components/product/variantSelector.vue')
		));
		app.component('sample-consent-modal', defineAsyncComponent(
			() => import(/* webpackChunkName: "sample-consent-modal" */ 'shared/components/product/sampleConsentModal.vue')
		));
		app.component('variant-add-to-basket', defineAsyncComponent(
			() => import(/* webpackChunkName: "variant-add-to-basket" */ 'shared/components/product/variantAddToBasket.vue')
		));
		app.component('variant-datasheet', defineAsyncComponent(
			() => import(/* webpackChunkName: "variant-datasheet" */ 'shared/components/product/variantDatasheet.vue')
		));
		app.component('variant-hcp-datasheet', defineAsyncComponent(
			() => import(/* webpackChunkName: "variant-hcp/datasheet" */ 'shared/components/product/hcpProduct/variantHcpDatasheet.vue')
		));
		app.component('read-only-description', defineAsyncComponent(
			() => import(/* webpackChunkName: "variant-datasheet" */ 'shared/components/product/detailViewComponents/readOnlyDescription.vue')
		));
		app.component('add-sample-to-basket', defineAsyncComponent(
			() => import(/* webpackChunkName: "add-sample-to-basket" */ 'shared/components/product/addSampleToBasket.vue')
		));
		app.component('hcp-popup', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-popup" */ 'shared/components/product/hcpPopup.vue')
		));
		app.component('add-to-basket-legacy', defineAsyncComponent(
			() => import(/* webpackChunkName: "add-to-basket-legacy" */ 'shared/components/product/addToBasket.vue')
		));
		app.component('checkout-modal-login', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-modal-login" */ 'multisite/components/checkout/checkoutModalLogin.vue')
		));

		app.mount(addToBasketMount);
	}

	const productDescriptionMounts = [...document.querySelectorAll('[data-product-description]')];
	productDescriptionMounts.forEach(productDescriptionMount => {
		const app = createApp({
			name: 'product description',
		});
		app.use(store);
		app.component('line-clamp', defineAsyncComponent(
			() => import(/* webpackChunkName: "line-clamp" */ 'shared/components/lineClamp.vue')
		));
		app.mount(productDescriptionMount);
	});

	const hcpModalDialog = document.getElementById('hcp-modal-dialog');
	if (hcpModalDialog) {
		const app = createApp({
			name: 'hcp modal dialog',
		});
		app.use(store);
		app.component('hcp-modal-dialog', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-modal-dialog" */ 'shared/components/HcpProfileBlocks/hcpModalDialog.vue')
		));
		app.mount(hcpModalDialog);
	}

	const hcpMainProfilePageMount = document.getElementById('hcp-main-profile-page');
	if (hcpMainProfilePageMount) {
		const app = createApp({
			name: 'new hcp profile page',
		});
		app.use(store);
		app.component('profile-nav', defineAsyncComponent(
			() => import('shared/components/HcpProfilePage/profileNav.vue')
		));
		const consumerShortcutsContainers = document.querySelectorAll('[data-shortcut-container]');
		if (consumerShortcutsContainers) {
			app.component('consumer-shortcut-container', defineAsyncComponent(
				() => import('multisite/components/myAccount/blocks/consumerShortcutContainer.vue')
			));
		}
		const coursesBlock = document.querySelectorAll('[data-courses-block]');
		if (coursesBlock) {
			app.component('hcp-certificates-block', defineAsyncComponent(
				() => import('shared/components/HcpProfilePage/hcpСertificatesBlock.vue')
			));
		}
		const bookmarksBlock = document.querySelectorAll('[data-bookmarks-block]');
		if (bookmarksBlock) {
			app.component('hcp-bookmarks-block', defineAsyncComponent(
				() => import('shared/components/HcpProfilePage/HcpBookmarksBlock.vue')
			));
		}
		if (document.getElementById('hcp-current-order')) {
			app.component('consumer-current-order', defineAsyncComponent(
				() => import('multisite/components/myAccount/blocks/consumerCurrentOrder.vue')
			));
		}
		if (document.getElementById('hcp-order-history-page')) {
			app.component('consumer-order-history-page', defineAsyncComponent(
				() => import('multisite/components/myAccount/pages/consumerOrderHistoryPage.vue')
			));
		}
		if (document.getElementById('hcp-personal-info')) {
			app.component('hcp-personal-info', defineAsyncComponent(
				() => import('shared/components/HcpProfilePage/Pages/hcpPersonalInformation.vue')
			));
		}
		if (document.getElementById('hcp-elearning-access')) {
			app.component('hcp-elearning-access', defineAsyncComponent(
				() => import('shared/components/HcpProfilePage/Pages/hcpProfileElearningAccess.vue')
			));
		}
		if (document.getElementById('hcp-profile-notifications')) {
			app.component('hcp-profile-notifications', defineAsyncComponent(
				() => import('shared/components/HcpProfilePage/Pages/hcpProfileNotifications.vue')
			));
		}
		if (document.getElementById('hcp-profile-elearningcode')) {
			app.component('hcp-profile-elearningcode', defineAsyncComponent(
				() => import('shared/components/HcpProfilePage/Pages/hcpProfileElearningCode.vue')
			));
		}
		if (document.getElementById('customized-experience-alert')) {
			app.component('customized-experience-alert', defineAsyncComponent(
				() => import('shared/components/HcpProfileBlocks/CustomizedExperienceAlert.vue')
			));
		}
		app.mount(hcpMainProfilePageMount);
	}

	const marketingPermissionsReminderMounts = document.querySelectorAll('.marketing-permission-reminder-block');
	if (marketingPermissionsReminderMounts.length) {
		marketingPermissionsReminderMounts.forEach(marketingPermissionsReminderEl => {
			const app = createApp({
				name: 'marketing permission reminder block',
			});
			app.use(store);
			app.component('marketing-permission-reminder-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "marketing-permission-reminder-block" */ 'shared/components/HcpProfileBlocks/marketingPermissionReminderBlock.vue')
			));
			app.mount(marketingPermissionsReminderEl);
		});
	}

	const actionButtonWithCtas = document.querySelectorAll('.action-button-with-cta');
	if (actionButtonWithCtas) {
		actionButtonWithCtas.forEach(actionButtonWithCtaEl => {
			const app = createApp({
				name: 'action button with cta',
			});
			app.component('action-button-with-cta', defineAsyncComponent(
				() => import(/* webpackChunkName: "action-button-with-cta" */ 'shared/components/actionButtonWithCTA/ActionButtonWithCTA.vue')
			));
			app.mount(actionButtonWithCtaEl);
		});
	}

	const WhatNextMount = document.getElementById('what-next-block');
	if (WhatNextMount) {
		const app = createApp({
			name: 'what next block',
		});
		app.component('what-next-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "what-next-block" */ 'shared/components/whatNextBlock.vue')
		));
		app.mount(WhatNextMount);
	}

	const setPasswordMount = document.getElementById('set-password-block');
	if (setPasswordMount) {
		const app = createApp({
			name: 'set password block',
		});
		app.use(store);
		app.config.globalProperties.$eventBus = eventBus;
		app.component('set-password-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "set-password-block" */ 'shared/components/HcpProfileBlocks/setPasswordBlock.vue')
		));
		app.mount(setPasswordMount);
	}

	const renewActivationLinkMount = document.getElementById('renew-activation-link-block');
	if (renewActivationLinkMount) {
		const app = createApp({
			name: 'renew activation link block',
		})
		app.use(store);
		app.component('renew-activation-link-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "renew-activation-link-block" */ 'shared/components/HcpProfileBlocks/renewActivationLinkBlock.vue')
		));
		app.mount(renewActivationLinkMount);
	}

	const renewActivationLinkSuccessMount = document.getElementById('renew-activation-link-success-block');
	if (renewActivationLinkSuccessMount) {
		const app = createApp({
			name: 'renew activation link success block',
		})
		app.use(store);
		app.component('renew-activation-link-success-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "renew-activation-link-success-block" */ 'shared/components/HcpProfileBlocks/renewActivationLinkSuccessBlock.vue')
		));
		app.mount(renewActivationLinkSuccessMount);
	}

	const HCPSignUpBlockMount = document.getElementById('hcp-signup-block');
	if (HCPSignUpBlockMount) {
		const app = createApp({
			name: 'hcp signup block',
		});
		app.use(store);
		app.config.globalProperties.$eventBus = eventBus;
		app.component('hcp-signup-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-signup-block" */ 'shared/components/HcpProfileBlocks/hcpSignUpBlock.vue')
		));
		app.mount(HCPSignUpBlockMount);
	}

	const HCPLoginBlockMount = document.querySelectorAll('.hcp-login-block');
	if (HCPLoginBlockMount) {
		HCPLoginBlockMount.forEach(HCPLoginBlockMountEl => {
			const app = createApp({
				name: 'hcp login block',
			});
			app.use(store);
			app.component('hcp-login-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "hcp-login-block" */ 'shared/components/HcpProfileBlocks/hcpLoginBlock.vue')
			));
			app.mount(HCPLoginBlockMountEl);
		});
	}

	const HCPCompleteSignUpPageMount = document.getElementById('hcp-complete-signup-page');
	if (HCPCompleteSignUpPageMount) {
		const app = createApp({
			name: 'hcp complete sign up page mount',
		});
		app.use(store);
		app.component('hcp-complete-signup-page', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-complete-signup-page" */ 'shared/components/HcpProfileBlocks/hcpCompleteSignUpPage.vue')
		));
		app.mount(HCPCompleteSignUpPageMount);
	}

	const HCPAccountSubscriptionsBlockMountArray = [...document.querySelectorAll('.hcp-account-subscription-block')];
	if (HCPAccountSubscriptionsBlockMountArray.length) {
		HCPAccountSubscriptionsBlockMountArray.forEach((subscription) => {
			const app = createApp({
				name: 'hcp account subscription block',
			});
			app.use(store);
			app.component('hcp-account-subscription-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "hcp-account-subscription-block" */ 'shared/components/HcpProfilePage/hcpAccountSubscriptionsBlock.vue')
			));
			app.mount(subscription);
		});
	}

	const coursesBlockMount = document.getElementById('courses-block');
	if (coursesBlockMount) {
		const app = createApp({
			name: ' elearning courses block',
		});
		app.use(store);
		app.component('elearning-courses-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "elearning-courses-block" */ 'shared/components/ElearningBlock/coursesBlock.vue')
		));
		app.mount(coursesBlockMount)
	}

	const videoPosterMountArray = [...document.querySelectorAll('[data-video-poster]')];
	if (videoPosterMountArray.length) {
		videoPosterMountArray.forEach((item) => {
			const app = createApp({
				name: 'video poster',
			});
			app.use(store);
			app.component('video-poster', defineAsyncComponent(
				() => import(/* webpackChunkName: "video-poster" */ 'shared/components/VideoElements/videoPoster.vue')
			));
			app.mount(item);
		});
	}

	const miniBasketCountMounts = document.querySelectorAll(".c-nav-basket");
	miniBasketCountMounts.forEach(miniBasketCountMount => {
		if (miniBasketCountMount) {
			const app = createApp({
				name: 'mini basket'
			});
			app.use(store);
			app.config.globalProperties.$eventBus = eventBus;
			app.component('fly-out-basket-activator', FlyOutBasketActivator);
			app.component('mini-basket-count', MiniBasketCount);
			app.mount(miniBasketCountMount);
		}
	});

	const globalUtilityMount = document.getElementById('global-utility-mount');
	if (globalUtilityMount) {
		const app = createApp({
			name: 'global utility'
		});
		app.use(store);
		app.use(plugin, defaultConfig({
			icons: {
				checkboxDecorator: iconCheckmark,
			},
		}));
		app.component('external-links-popup', ExternalLinksPopup);
		app.component('modal-dialog', ModalDialog);
		app.component('basket-nav-tracker', BasketNavTracker);
		app.component('form-post-tracker', FormPostTracker);
		app.component('login-tracker', LoginTracker);
		app.component('quick-sample-tracker', QuickSampleTracker);
		app.component('spinner', Spinner);
		app.component('phone-field', PhoneField);
		app.component('global-spinner', GlobalSpinner);
		app.component('login-expired-modal', LoginExpiredModal);
		app.component('navigate-to-gated-sub-site-modal', defineAsyncComponent(
			() => import('shared/components/navigateToGatedSubSiteModal/navigateToGatedSubSiteModal.vue')
		));
		app.mount(globalUtilityMount);
	}

	const basketFormMount = document.getElementById('basket-form');
	if (basketFormMount) {
		const app = createApp({
			name: 'sample checkout form',
		});
		app.use(store);
		app.component('phone-field', defineAsyncComponent(
			() => import('shared/components/phoneField.vue')
		));
		app.component('country-selector-expando', defineAsyncComponent(
			() => import('multisite/components/countrySelectorExpando.vue')
		))
		app.mount(basketFormMount);
	}

	const basketInteractionTrackerMount = document.getElementById('basket-interaction-tracker-mount');
	if (basketInteractionTrackerMount) {
		const app = createApp({
			name: 'basket interaction tracker',
		});
		app.use(store);
		app.component('basket-interaction-tracker', defineAsyncComponent(
			() => import('shared/components/trackers/basketInteractionTracker.vue')
		));
		app.mount(basketInteractionTrackerMount);
	}

	const sampleFormProductsMount = document.getElementById('sample-form-products');
	if (sampleFormProductsMount) {
		const app = createApp({
			name: 'sample from products',
		});
		app.use(store);
		app.component('sample-form-products', defineAsyncComponent(
			() => import(/* webpackChunkName: "sample-form-products" */ 'shared/components/sampleFormProducts.vue')
		));
		app.component('remove-from-cart-tracker', defineAsyncComponent(
			() => import('shared/components/trackers/removeFromCartTracker.vue')
		));
		app.mount(sampleFormProductsMount)
	}

	const sampleProductFormMountArray = [...document.querySelectorAll('.js-order-sample-block')];
	if (sampleProductFormMountArray.length) {
		sampleProductFormMountArray.forEach((item) => {
			const app = createApp({
				name: 'sample product form',
			});
			app.use(store);
			app.component('sample-product-form', defineAsyncComponent(
				() => import(/* webpackChunkName: "sample-product-form" */ 'shared/components/sampleProductForm.vue')
			));
			app.mount(item);
		});
	}

	const consumerSignupModalMount = document.getElementById('consumer-signup-modal-mount');
	if (consumerSignupModalMount) {
		const app = createApp({
			name: 'consumer sign up modal',
		});
		app.use(store);
		app.component('consumer-signup-modal', defineAsyncComponent(
			() => import(/* webpackChunkName: "consumer-signup-modal" */ 'shared/components/consumerSignup/consumerSignupModal.vue')
		));
		app.mount(consumerSignupModalMount);
	}

	const consumerSignupInfoModalMount = document.getElementById('info-message-modal-mount');
	if (consumerSignupInfoModalMount) {
		const app = createApp({
			name: 'info message modal',
		});
		app.use(store);
		app.component('info-message-modal', defineAsyncComponent(
			() => import(/* webpackChunkName: "info-message-modal" */ 'shared/components/infoMessageModal.vue')
		));
		app.mount(consumerSignupInfoModalMount);
	}

	const globalSearchNav = document.getElementById('global-search-nav');
	if (globalSearchNav) {
		const app = createApp({
			name: 'global search nav',
		});
		app.use(store);
		app.component('global-search-nav', defineAsyncComponent(
			() => import(/* webpackChunkName: "global-search-nav" */ 'shared/components/globalSearch/globalSearchNav.vue')
		));
		app.mount(globalSearchNav);
	}

	const globalSearchPopup = document.getElementById('global-search-popup');
	if (globalSearchPopup) {
		const app = createApp({
			name: 'global search popup',
		});
		app.use(store);

		app.component('global-search-popup', defineAsyncComponent(
			() => import(/* webpackChunkName: "global-search-popup" */ 'shared/components/globalSearch/globalSearchPopup.vue')
		));
		app.mount(globalSearchPopup);
	}

	const globalSearchPage = document.getElementById('global-search-page');
	if (globalSearchPage) {
		const app = createApp({
			name: 'global search page',
		});
		app.use(store);
		app.component('global-search-page', defineAsyncComponent(
			() => import(/* webpackChunkName: "global-search-page" */ 'shared/components/globalSearch/globalSearchPage.vue')
		));
		app.mount(globalSearchPage);
	}

	const globalSearchArticlesResultPage = document.getElementById('global-search-article-page');
	if (globalSearchArticlesResultPage) {
		const app = createApp({
			name: 'global search article page',
		});
		app.use(store);
		app.component('global-search-article-page', defineAsyncComponent(
			() => import(/* webpackChunkName: "global-search-article-page" */ 'shared/components/globalSearch/globalSearchArticlesResultPage.vue')
		));
		app.mount(globalSearchArticlesResultPage);
	}

	const globalSearchProductsResultPage = document.getElementById('global-search-product-page');
	if (globalSearchProductsResultPage) {
		const app = createApp({
			name: 'global search product page',
		});
		app.use(store);
		app.component('global-search-product-page', defineAsyncComponent(
			() => import(/* webpackChunkName: "global-search-product-page" */ 'shared/components/globalSearch/globalSearchProductsResultPage.vue')
		));
		app.mount(globalSearchProductsResultPage);
	}

	const hcpMedicalSpecialityGatePage = document.getElementById('speciality-gate-page');
	if (hcpMedicalSpecialityGatePage) {
		const app = createApp({
			name: 'medical speciality gate page',
		});
		app.use(store);
		app.component('medical-speciality-gate-page', defineAsyncComponent(
			() => import('shared/components/HcpMedicalSpecialityGatePage.vue')
		));
		app.mount(hcpMedicalSpecialityGatePage);
	}

	const contentLibrary = document.getElementById('content-library');
	if (contentLibrary) {
		const app = createApp({
			name: 'content library',
		});
		app.use(store);
		app.component('content-library', defineAsyncComponent(
			() => import(/* webpackChunkName: "content-library" */ 'shared/components/contentLibrary/contentLibrary.vue')
		));
		app.mount(contentLibrary);
	}

	const carouselLibraries = document.querySelectorAll('.carousel-library');
	if (carouselLibraries) {
		carouselLibraries.forEach((item) => {
			const app = createApp({
				name: 'carousel libraries'
			});
			app.use(store);
			app.component('carousel-library', defineAsyncComponent(
				() => import(/* webpackChunkName: "carousel-library" */ 'shared/components/contentLibrary/carouselLibraryBlock.vue')
			));
			app.mount(item);
		})
	}

	const multiLevelMenu = document.getElementById('multi-level-menu');
	if (multiLevelMenu) {
		const app = createApp({
			name: 'multi level menu',
		});
		app.use(store);
		app.component('multi-level-menu', defineAsyncComponent(
			() => import(/* webpackChunkName: "multi-level-menu" */ 'shared/components/MultiLevelMenu/multiLevelMenu.vue')
		));
		app.mount(multiLevelMenu);
	}

	const podcastPlayers = [...document.querySelectorAll('[data-podcast-player]')];
	if (podcastPlayers) {
		podcastPlayers.forEach((item) => {
			const app = createApp({
				name: 'podcast player block',
			});
			app.component('podcast-player-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "podcast-player-block" */ 'shared/components/podcastPlayerBlock.vue')
			));
			app.mount(item);
		});
	}

	const productCateogryCarousels = document.querySelectorAll('.product-category-carousel-mount');
	if (productCateogryCarousels) {
		productCateogryCarousels.forEach((item) => {
			const app = createApp({
				name: 'product category carousel',
			});
			app.use(store);
			app.component('product-category-carousel', defineAsyncComponent(
				() => import(/* webpackChunkName: "multisite-product-category-carousel" */ 'multisite/components/minishop/productCategoryCarousel.vue')
			));
			app.mount(item);
		})
	}

	const ctaHeroBanner = document.getElementById('cta-hero-banner');
	if (ctaHeroBanner) {
		const app = createApp({
			name: 'cta hero banner',
		});
		app.use(store);
		app.component('cta-hero-banner', defineAsyncComponent(
			() => import(/* webpackChunkName: "cta-hero-banner" */ 'shared/components/ctaHeroBanner.vue')
		));
		app.component('send-campaign-id-cta-button', defineAsyncComponent(
			() => import('shared/components/actionButtonWithCTA/SendCampaignIdCtaButton.vue')
		));
		app.component('cta-button-with-popup-form', defineAsyncComponent(
			() => import('shared/components/actionButtonWithCTA/CtaButtonWithPopupForm.vue')
		));
		app.component('form-kit-builder-container', defineAsyncComponent(
			() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue')
		));
		initFormKitSchema(app);
		app.mount(ctaHeroBanner);
	}

	const contentGate = document.getElementById('content-gate');
	if (contentGate) {
		const app = createApp({
			name: 'content gate',
		});
		app.use(store);
		app.component('content-gate', defineAsyncComponent(
			() => import(/* webpackChunkName: "content-gate" */ 'shared/components/contentLibrary/contentGate.vue')
		));
		app.component('hcp-login-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-login-block" */ 'shared/components/HcpProfileBlocks/hcpLoginBlock.vue')
		));
		app.mount(contentGate);
	}

	const contentGateButtons = [...document.querySelectorAll('[data-content-gate-button]')];
	if (contentGateButtons) {
		contentGateButtons.forEach((item) => {
			const app = createApp({
				name: 'content gate button',
			});
			app.use(store);
			app.component('content-gate-button', defineAsyncComponent(
				() => import(/* webpackChunkName: "content-gate-button" */ 'shared/components/contentLibrary/contentGateButton.vue')
			));
			app.mount(item);
		});
	}

	const contentRecommended = [...document.querySelectorAll('[data-content-recommended]')];
	if (contentRecommended) {
		contentRecommended.forEach((item) => {
			const app = createApp({
				name: 'content recommended block',
			});
			app.component('content-recommended-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "content-recommended-block" */ 'shared/components/contentLibrary/contentRecommendedBlock.vue')
			));
			app.mount(item);
		});
	}

	const contentInfoCard = document.getElementById('content-info-card');
	if (contentInfoCard) {
		const app = createApp({
			name: 'content info card',
		});
		app.use(store);
		app.component('content-info-card', defineAsyncComponent(
			() => import(/* webpackChunkName: "content-info-card" */ 'shared/components/contentLibrary/contentInfoCard.vue')
		));
		app.component('send-campaign-id-cta-button', defineAsyncComponent(
			() => import('shared/components/actionButtonWithCTA/SendCampaignIdCtaButton.vue')
		));
		app.component('cta-button-with-popup-form', defineAsyncComponent(
			() => import('shared/components/actionButtonWithCTA/CtaButtonWithPopupForm.vue')
		));
		app.component('form-kit-builder-container', defineAsyncComponent(
			() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue')
		));
		initFormKitSchema(app);
		app.mount(contentInfoCard);
	}

	const contentAlternativeDate = document.getElementById('content-alternative-date');
	if (contentAlternativeDate) {
		const app = createApp({
			name: 'content alternative date',
		});
		app.component('content-alternative-date', defineAsyncComponent(
			() => import(/* webpackChunkName: "content-alternative-date" */ 'shared/components/contentLibrary/contentAlternativeDate.vue')
		));
		app.mount(contentAlternativeDate);
	}

	const utiRiskFactorModel = document.getElementById('uti-risk-factor-model');
	if (utiRiskFactorModel) {
		const app = createApp({
			name: 'uti risk factor model',
		});
		app.component('uti-risk-factor-model', defineAsyncComponent(
			() => import(/* webpackChunkName: "uti-risk-factor-model" */ 'shared/components/UTIRiskFactorModel/utiRiskFactorModel.vue')
		));
		app.mount(utiRiskFactorModel);
	}


	const breadcrumbMount = document.getElementById('breadcrumb');
	if (breadcrumbMount) {
		const app = createApp({
			name: 'breadcrumb mount', // if the app is called breadcrumb like the component the app will crash with a call stack exceeded error
		});
		app.component('breadcrumb', Breadcrumb);
		app.mount(breadcrumbMount);
	}

	const heyloCheckout = document.getElementById('heylo-checkout');
	if (heyloCheckout) {
		const app = createApp({
			name: 'heylo checkout',
		});
		app.use(store);
		app.component('heylo-checkout', defineAsyncComponent(
			() => import(/* webpackChunkName: "heylo-checkout" */ 'multisite/components/heylo/checkout/checkout.vue')
		));
		app.mount(heyloCheckout);
	}

	const heyloTool = document.getElementById('heylo-tool');
	if (heyloTool) {
		const app = createApp({
			name: 'heylo tool',
		});
		app.use(store);
		app.component('heylo-tool', defineAsyncComponent(
			() => import(/* webpackChunkName: "heylo-tool" */ 'shared/components/heyloTool/heyloTool.vue')
		));
		app.mount(heyloTool);
	}

	const mediaBannerMount = document.getElementById('media-banner');
	if (mediaBannerMount) {
		const app = createApp({
			name: 'media banner',
		});
		app.use(store);
		app.component('media-banner', defineAsyncComponent(
			() => import(/* webpackChunkName: "media-banner" */ 'shared/components/mediaBannerBlock/mediaBannerBlock.vue')
		));
		app.mount(mediaBannerMount);
	}

	const healthCheckTool = document.getElementById('health-check-tool');
	if (healthCheckTool) {
		const app = createApp({
			name: 'health check tool',
		});
		app.use(store);
		app.component('health-check-tool', defineAsyncComponent(
			() => import(/* webpackChunkName: "health-check-tool" */ 'shared/components/healthCheckTool/healthCheckTool.vue')
		));
		app.component('checkout-modal-login', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-modal-login" */ 'multisite/components/checkout/checkoutModalLogin.vue')
		));
		app.component('references-list', defineAsyncComponent(
			() => import(/* webpackChunkName: "references-list" */ 'shared/components/ReferencesList.vue')
		));
		app.component(
			'form-kit-builder-container',
			defineAsyncComponent(() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue'))
		);
		initFormKitSchema(app);
		app.mount(healthCheckTool);
	}

	const ctaBlocks = [...document.querySelectorAll('[data-cta-block]')];
	if (ctaBlocks) {
		ctaBlocks.forEach((item) => {
			const app = createApp({
				name: 'cta block',
			});
			app.component('cta-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "cta-block" */ 'shared/components/articleElements/ctaBlock.vue')
			));

			app.component('cta-button-with-popup-form', defineAsyncComponent(
				() => import('shared/components/actionButtonWithCTA/CtaButtonWithPopupForm.vue')
			));
			app.component('form-kit-builder-container', defineAsyncComponent(
				() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue')
			));
			initFormKitSchema(app);
			app.use(store);
			app.mount(item);
		});
	}

	const subNavigationBlock = [...document.querySelectorAll('[data-sub-navigation]')];
	if (subNavigationBlock) {
		subNavigationBlock.forEach((item) => {
			const app = createApp({
				name: 'sub navigation block',
			});
			app.component('sub-navigation-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "sub-navigation-block" */ 'shared/components/articleElements/subNavigationBlock.vue')
			));
			app.mount(item);
		});
	}

	const documentSearchComponent = document.getElementById("document-search");
	if (documentSearchComponent) {
		const app = createApp({
			name: 'document search',
		});
		app.use(store);
		app.component('document-search', defineAsyncComponent(
			() => import(/* webpackChunkName: "document-search" */ 'shared/components/documentSearch/documentSearch.vue')
		));
		app.mount(documentSearchComponent);
	}

	const factsBoxBlock = [...document.querySelectorAll('[data-fact-block]')];
	if (factsBoxBlock) {
		factsBoxBlock.forEach((item) => {
			const app = createApp({
				name: 'fact box block',
			});
			app.component('fact-box-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "fact-box-block" */ 'shared/components/articleElements/factBoxBlock.vue')
			));
			app.mount(item);
		});
	}

	const videoCarouselBlocks = [...document.querySelectorAll('[data-video-carousel-block]')];
	if (videoCarouselBlocks) {
		videoCarouselBlocks.forEach(videoCarouselBlock => {
			const app = createApp({
				name: 'video carousel block',
			});
			app.component('video-carousel-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "video-carousel-block" */ 'shared/components/VideoCarouselBlock/VideoCarouselBlock.vue')
			));
			app.component('video-button-play', defineAsyncComponent(
				() => import('shared/components/VideoCarouselBlock/VideoButtonPlay.vue')
			));
			app.mount(videoCarouselBlock);
		});
	}

	const relatedContentBlock = [...document.querySelectorAll('[data-related-content]')];
	if (relatedContentBlock) {
		relatedContentBlock.forEach((item) => {
			const app = createApp({
				name: 'related content block',
			});
			app.component('related-content-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "related-content-block" */ 'shared/components/contentLibrary/relatedContentBlock.vue')
			));
			app.mount(item);
		});
	}

	const productHighlightsBlock = document.getElementById('product-highlights-block');
	if (productHighlightsBlock) {
		const app = createApp({
			name: 'product highlights block',
		});
		app.use(store);
		app.component('product-highlights-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "product-highlights-block" */ 'shared/components/ProductHighlights/ProductHighlightsBlock.vue')
		));
		app.mount(productHighlightsBlock);
	}

	const highlightedMultimediaBlocks = [...document.querySelectorAll('[data-highlighted-multimedia]')];
	if (highlightedMultimediaBlocks) {
		highlightedMultimediaBlocks.forEach((item) => {
			const app = createApp({
				name: 'highlighted multimedia block',
			});
			app.use(store);
			app.component('highlighted-multimedia-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "highlighted-multimedia-block" */ 'shared/components/mediaItems/HighlightedMultimediaBlock.vue')
			));
			app.component('video-poster', defineAsyncComponent(
				() => import(/* webpackChunkName: "video-poster" */ 'shared/components/VideoElements/videoPoster.vue')
			));
			app.mount(item);
		});
	}

	const practitionersGuideOverview = document.getElementById('practitioners-guide-overview');
	if (practitionersGuideOverview) {
		const app = createApp({
			name: 'practitioners guide overview',
		});
		app.component('practitioners-guide-overview', defineAsyncComponent(
			() => import(/* webpackChunkName: "practitioners-guide-overview" */ 'shared/components/PractitionersGuide/PractitionersGuideOverview.vue')
		));
		app.mount(practitionersGuideOverview);
	}

	const ctaButtomWithFormKits = [...document.querySelectorAll('[data-cta-button-formkit]')];
	if (ctaButtomWithFormKits) {
		ctaButtomWithFormKits.forEach((item) => {
			const app = createApp({
				name: 'cta button with formkit form',
			});
			app.component('cta-button-with-popup-form', defineAsyncComponent(
				() => import('shared/components/actionButtonWithCTA/CtaButtonWithPopupForm.vue')
			));
			app.component('form-kit-builder-container', defineAsyncComponent(
				() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue')
			));
			initFormKitSchema(app);
			app.use(store);
			app.mount(item);
		});
	}

	const zoomInModalButtons = [...document.querySelectorAll('[data-zoom-in-modal-button]')];
	if (zoomInModalButtons) {
		zoomInModalButtons.forEach((item) => {
			const app = createApp({
				name: 'zoom in modal button',
			});
			app.component('zoom-in-modal-button', defineAsyncComponent(
				() => import(/* webpackChunkName: "zoom-in-modal-button" */ 'shared/components/mediaItems/ZoomInModalButton.vue')
			));
			app.mount(item);
		});
	}

	const uploadCuttingProfile = [...document.querySelectorAll('[data-upload-cutting-template-mount]')];
	if (uploadCuttingProfile) {
		uploadCuttingProfile.forEach((item) => {
			const app = createApp({
				name: 'upload cutting profile',
			});
			app.component('upload-cutting-template', defineAsyncComponent(
				() => import(/* webpackChunkName: "upload-cutting-template" */ 'shared/components/cuttingTemplates/uploadCuttingTemplate.vue')
			));
			app.use(plugin, defaultConfig);
			app.use(store);
			app.mount(item);
		});
	}

	const simpleCardsWithCta = document.querySelectorAll('.simple-cards-with-cta');
	if (simpleCardsWithCta) {
		simpleCardsWithCta.forEach(simpleCardsWithCtaEl => {
			const app = createApp({
				name: 'simple cards with cta',
			});
			app.component('simple-cards-with-cta', defineAsyncComponent(
				() => import(/* webpackChunkName: "simple-cards-with-cta" */ 'shared/components/simpleCardsWithCta/simpleCardsWithCta.vue')
			));
			app.mount(simpleCardsWithCtaEl);
		});
	}

	const globalModal = document.getElementById('modal-mount');
	if (globalModal) {
		const app = createApp({
			name: 'global modal app'
		});
		app.component('modal', defineAsyncComponent(
			() => import(/* webpackChunkName: "modal" */ 'shared/components/modal/modal.vue')
		));
		app.use(store);
		app.mount(globalModal)
	}

	const referencesList = document.getElementById('references-list');
	if (referencesList) {
		const app = createApp({
			name: 'references list'
		});
		app.component('references-list', defineAsyncComponent(
			() => import(/* webpackChunkName: "references-list" */ 'shared/components/ReferencesList.vue')
		));
		app.mount(referencesList);
	}

	const formkitBuilderContainerApps = [...document.querySelectorAll('[data-form-builder-block]')];
	formkitBuilderContainerApps.forEach((appContainer, index) => {
		const app = createApp({
			name: `form-builder-block-${index}`,
		});
		app.use(store);
		app.component('form-builder-block', defineAsyncComponent(
			() => import('shared/components/formBuilderBlock/FormBuilderBlock.vue')
		));
		app.component('form-kit-builder-container', defineAsyncComponent(
			() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue')
		));
		initFormKitSchema(app);
		app.mount(appContainer);
	});
}

export function initCheckoutFlow() {
	const formEl = document.querySelector("[data-checkout-item]");
	if (formEl) {
		const app = createApp({
			name: 'checkout item',
		});
		app.use(store);
		app.component('prescription-upload-block', defineAsyncComponent(
			() => import(/* webpackChunkName: "prescription-upload-block" */ 'multisite/components/formElements/checkoutPrescriptionBlocks/checkoutPrescriptionUploadBlock.vue')
		));
		app.component('checkout-modal-login', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-modal-login" */ 'multisite/components/checkout/checkoutModalLogin.vue')
		));
		app.component('checkout-submitted-summary', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-submitted-summary" */ 'multisite/components/checkout/checkoutSubmittedSummary.vue')
		));
		app.component('order-quote-confirm', defineAsyncComponent(
			() => import(/* webpackChunkName: "order-quote-confirm" */ 'multisite/components/checkout/orderQuoteConfirm.vue')
		));
		app.component('progress-bar', defineAsyncComponent(
			() => import(/* webpackChunkName: "progress-bar" */ 'shared/components/progressBar/progressBar.vue')
		));
		app.component('delivery-cart', defineAsyncComponent(
			() => import(/* webpackChunkName: "delivery-cart" */ '/Features/GlobalCheckout/SharedComponents/deliveryCart.vue')
		));
		app.component('hcp-checkout-summary', defineAsyncComponent(
			() => import(/* webpackChunkName: "hcp-checkout-summary" */ 'multisite/components/checkout/HcpCheckoutSummary.vue')
		));
		initFormKitSchema(app);
		app.mount(formEl);
	}
}

function initCmsContentBlocks() {
	const elements = document.querySelectorAll("[data-cms-content-block]");
	if (elements.length) {
		elements.forEach(element => {
			const app = createApp({
				name: element.dataset.blockName,
			});
			app.component('hyperlink-navigation-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "hyperlink-navigation-block" */ 'multisite/components/cmsContentBlocks/hyperlinkNavigationBlock.vue')
			));
			app.component('sticky-cta-button-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "sticky-cta-button-block" */ 'multisite/components/cmsContentBlocks/stickyCtaButtonBlock.vue')
			));
			app.component('card-slider-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "card-slider-block" */ 'multisite/components/cmsContentBlocks/cardSlider/cardSliderBlock.vue')
			));
			app.component('icon-text-list-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "icon-text-list-block" */ 'multisite/components/cmsContentBlocks/iconTextListBlock.vue')
			));
			app.component('campaign-cta-button-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "campaign-cta-button-block" */ 'multisite/components/cmsContentBlocks/campaignCtaButtonBlock.vue')
			));
			app.component('general-link-block', defineAsyncComponent(
				() => import(/* webpackChunkName: "general-link-block" */ 'multisite/components/cmsContentBlocks/generalLink.vue')
			));
			app.use(store);
			app.mount(element);
		});
	}
}

initGlobalAxios();
initGlobalSettingsApp();
initVueApps();
initCheckoutFlow();
initCmsContentBlocks();

boot();
initPostbackScrollPosition();
ieNotification.init();
initHeylo();


/**
 *
 * @deprecated is to be deprecated in the future due to the updated version of the navigation (Feature 81078)
 * However, to preserve the correct working of the "preserving the selected items state" for the "onboarding reordering module",
 * the similar functionality should be implemented in the new navigation.
 * Without that, the selection would not be "reset" after the user logs out and the initial state of the selection
 * won't be visible after repeated log-in
 *
 * Please refer to "productsBlock.vue" and "productsBlock.utils.ts" for more details.
 *
 * When removed, please remove the references from the "_HeaderMultisite.cshtml"
 */
window.onLogout = (e) => {
	e.preventDefault()

	/**
	 * Removal of the selected items state
	 * @ref productsBlock.utils.ts
	 */
	const storage = storageFactory(window.sessionStorage);
	storage.removeItem('cp-customer-products-selection');

	location.assign(e.currentTarget.href)
}
